<template>
  <div class="animated fadeIn">
    <div v-if="loadingLocation" class="row justify-content-center">
      <div class="col mt-3 mb-3">
        <animation />
      </div>
    </div>

    <b-card v-else>
      <div slot="header">
        {{ titleCardForm }}
      </div>

      <b-card-body>
        <b-form
          @submit.prevent="save"
          novalidate
          :validated="isValid"
          autocomplete="off"
        >
          <b-row class="mx-md-n3 mx-1">
            <div class="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-12">
              <b-form-group
                id="name"
                label="Nome"
                label-for="name"
                description="Código de identificação do endereço"
                :invalid-feedback="nameInvalidFeedback"
                :state="name"
              >
                <b-form-input
                  required
                  id="name-input"
                  type="text"
                  v-model="formData.name"
                  :state="name"
                  placeholder="Ex: b-17-20-50"/>
              </b-form-group>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12">
              <b-form-group
                id="barcode"
                label="Código de barras"
                label-for="barcode"
                description="Código de barras do endereço"
              >
                <b-form-input
                  disabled
                  id="barcode-input"
                  type="text"
                  v-model="formData.barcode"/>
              </b-form-group>
            </div>
          </b-row>

          <b-row class="mx-md-n3 mx-1">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
              <b-card bg-variant="light">
                <b-row class="mx-md-n3 mx-1">
                  <div class="col-12">
                    <label>Tipo</label>

                    <b-form-radio-group v-model="formData.type" @change="reloadFormData"
                      :disabled="formData.id && numberOfItemsThatHaveQuantity > 1">
                      <b-form-radio name="typeOptions" value="F">
                        Item fixo
                      </b-form-radio>
                      <b-form-radio name="typeOptions" value="D" class="my-2">
                        Item dinâmico
                      </b-form-radio>
                      <b-form-radio name="typeOptions" value="U">
                        Item indefinido
                      </b-form-radio>
                    </b-form-radio-group>

                    <p class="text-muted mt-sm-0 mt-2">{{typeDescription}}</p>
                    <small v-if="formData.id && numberOfItemsThatHaveQuantity > 1" class="text-danger">
                      Não é possível alterar o tipo de um endereço que possua mais de um item
                    </small>
                  </div>
                </b-row>
              </b-card>
            </div>

            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
              <!-- <b-card bg-variant="light">

                <b-row>
                  <b-col md="12" sm="12" class="margin-bottom">
                    <h5>Alocações Reservas</h5>
                    <b-badge
                      class="margin-left"
                      variant="info"
                      v-for="reserve in formData.reserves"
                      :key="reserve.uuid">
                      {{ reserve.name }}
                    </b-badge>
                  </b-col>

                  <b-col md="10" sm="12">
                    <model-list-select
                      :list="allocations"
                      option-value="uuid"
                      option-text="name"
                      v-model="selectedReserve"
                      placeholder="Selecione Alocação Reserva"
                      @searchchange="searchAllocation"
                    />
                  </b-col>

                  <b-col md="2" sm="12">
                    <div v-if="searchAllocationReserveInProcess">
                      <animation/>
                    </div>
                    <div v-else>
                      <b-button variant="outline-primary" @click.prevent="addReserve">
                        <i class="fa fa-plus"></i>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-card> -->

              <b-card bg-variant="light">
                <b-row class="mx-md-n3 mx-1">
                  <div class="col-12 margin-bottom">
                    <h5>Item</h5>
                  </div>

                  <div class="col-md-10 col-10">
                    <model-list-select
                      :list="items"
                      option-value="id"
                      option-text="label"
                      v-model="selectedItem"
                      placeholder="Digite para pesquisar..."
                      @searchchange="searchItem"
                      :isDisabled="formData.type === 'U' || formData.quantity > 0"
                      :filterPredicate="alwaysTrueFilterPredicate"
                    />
                    <small v-if="formData.id && currentType !== 'U' && formData.type !== 'U' && formData.quantity > 0" class="text-danger">
                      Não é possível alterar o item de um endereço com quantidade maior que zero
                    </small>
                    <small v-if="formData.type === 'U'" class="text-danger">
                      Endereços com item indefinido não possuem item
                    </small>
                    <small v-if="currentType === 'U' && formData.type !== 'U'" class="text-warning">
                      O item será automaticamente atribuído assim que o endereço for salvo
                    </small>
                  </div>

                  <div class="col-md-2 col-2">
                    <div v-if="searchItemInProcess">
                      <animation/>
                    </div>
                  </div>

                  <div class="col-12 margin-bottom mt-3">
                    <h5>Marca</h5>
                  </div>

                  <div class="col-md-10 col-10">
                    <model-list-select
                      :list="brands"
                      option-value="id"
                      option-text="name"
                      v-model="selectedBrand"
                      placeholder="Digite para pesquisar..."
                      @searchchange="searchBrand"
                      :filterPredicate="alwaysTrueFilterPredicate"
                    />
                  </div>

                  <div class="col-md-2 col-2">
                    <div v-if="searchBrandInProcess">
                      <animation/>
                    </div>
                  </div>

                  <div class="col-6 mt-3">
                    <b-form-group
                      id="quantityMin"
                      label="Mínimo"
                      label-for="input-quantityMin"
                      :state="minimal"
                    >
                      <b-form-input
                        id="input-quantityMin"
                        v-model="formData.quantityMin"
                        :state="minimal"
                        placeholder="Ex: 10"
                        :disabled="capacityDisabled"/>
                      <small v-if="capacityDisabled" class="text-danger">
                        Não é possível controlar a quantidade mínima de endereços com item indefinido
                      </small>
                    </b-form-group>
                  </div>

                  <div class="col-6 mt-3">
                    <b-form-group
                        id="quantityMax"
                        label="Máximo"
                        label-for="input-quantityMax"
                        :state="capacity"
                      >
                      <b-form-input
                        id="input-quantityMax"
                        v-model="formData.quantityMax"
                        :state="capacity"
                        placeholder="Ex: 1000"
                        :disabled="capacityDisabled"/>
                      <small v-if="capacityDisabled" class="text-danger">
                        Não é possível controlar a quantidade máxima de endereços com item indefinido
                      </small>
                    </b-form-group>
                  </div>
                </b-row>
              </b-card>
            </div>

            <div class="col-12 mt-3">
              <div class="card bg-light pt-3 px-3">
                <label>Grupos</label>

                <div v-if="loadingLocationGroupers" class="mb-5 pb-5">
                  <animation />
                </div>

                <div v-else-if="formData.locationGroups" class="row">
                  <div v-for="(group, index) in formData.locationGroups" :key="index" class="col-xl-2 col-sm-3 col-6">
                    <div class="mb-3">
                      <label>{{group.locationGrouper.name}}</label>
                      <b-form-input :list="'groups-' + group.locationGrouper.id" v-model="group.name"
                        @keyup="locationGroupSearchChanged(group)"></b-form-input>
                      <datalist v-if="locationGroupsSuggestions[group.locationGrouper.id]" :id="'groups-' + group.locationGrouper.id">
                        <option v-for="(suggestion, index) in locationGroupsSuggestions[group.locationGrouper.id]" :key="index"
                          :value="suggestion.name"></option>
                      </datalist>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-row>

          <div class="row mx-md-n3 mx-1">
            <div class="col">
              <div class="row justify-content-between">

                <div class="col-auto">
                  <button-back></button-back>
                </div>

                <div class="col-auto">
                  <b-button v-if="!saving && formData.id" type="button" variant="warning"
                    :to="'/orders/item-reallocation/new?singleLocation=1&sourceLocationId=' + formData.id"
                  >
                    Realocar
                  </b-button>
                </div>

                <div class="col-auto">
                  <b-button v-if="!saving" type="submit" variant="primary" class="btn-lg" :disabled="searchItemInProcess || searchBrandInProcess">
                    Salvar
                  </b-button>
                </div>

              </div>
            </div>
            <div v-if="saving" class="col-auto">
              <animation/>
            </div>
          </div>
        </b-form>

        <div class="card mt-4" v-if="formData.id && currentType === 'U'">
          <div class="card-header">Itens</div>
          <div class="card-body p-0">
            <div v-if="loadingLocationBalances" class="mb-5 pb-5">
              <animation />
            </div>

            <div v-if="!loadingLocationBalances && (!formData.balances || !formData.balances.length)">
              <div class="row bg-light mx-0 py-3">
                <div class="col text-center">
                  Esse endereço não possui itens
                </div>
              </div>
            </div>

            <div v-if="!loadingLocationBalances && formData.balances && formData.balances.length">
              <div v-for="(item, index) in formData.balances" class="row py-3 border mx-0" :key="index" :class="{ 'bg-light': index % 2 != 0 }">
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                  <div class="row">
                    <div class="col-12">
                      <span class="font-weight-bold">Descrição: </span>{{item.description}}
                    </div>
                    <div class="col-12">
                      <span class="font-weight-bold">Reduzido: </span>{{getItemReduzidoFromRef(item.ref)}}
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <span class="font-weight-bold">Quantidade: </span>{{item.totalAllocated}}
                </div>
              </div>

              <nav class="mt-3">
                <b-pagination
                  :disabled="loadingLocationBalances"
                  align="center"
                  :limit="10"
                  :total-rows="balancesTotalRows"
                  :per-page="balancesPerPage"
                  prev-text="Anterior"
                  next-text="Próximo"
                  number-of-page="balancesNumberOfPages"
                  v-model="balancesCurrentPage"
                  @change="balancesPageChanged"/>
              </nav>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import { allocation } from '@/service'
  import CForm from './c-form'
  import ButtonBack from './c-button-back'
  import { ModelListSelect } from 'vue-search-select'
  import { ValidateText, ValidationNumber } from '@/tools/validations'
  import Animation from '@/components/loaders/animation'
  import { httpClient } from '@/service'
  import shared from '@/shared/shared'
  import { setTimeout } from 'timers';

  export default {
    name: 'form-allocations',
    components: { Animation, CForm, ModelListSelect, ButtonBack },

    data () {
      return {
        inProcess: false,
        searchAllocationReserveInProcess: false,
        searchItemInProcess: false,
        submitting: false,
        isValid: false,
        selectedLocal: {},
        allocations: [],
        items: [],
        titleCardForm: 'Endereço',
        selectedReserve: {},
        selectedItem: {},
        formData: {
          id: null,
          name: '',
          quantityMax: '',
          quantityMin: '',
          item: {},
          type: 'F',
          balances: [],
          barcode: null,
          locationGroups: []
        },
        saving: false,
        itemSearchTimeout: null,
        loadingLocationBalances: false,
        loadingNumberOfitems: false,
        numberOfItemsThatHaveQuantity: 2,
        balancesCurrentPage: 1,
        balancesPerPage: 10,
        balancesTotalRows: 10,
        currentType: null,
        loadingLocation: false,
        loadingLocationGroupers: false,
        locationGroupers: [],
        locationGroupsSuggestions: {},
        loadingLocationGroupSuggestions: {},
        locationGroupSuggestionSearchTimeout: null,
        brands: [],
        selectedBrand: {},
        searchBrandInProcess: false,
        brandSearchTimeout: null
      }
    },

    mounted: function () {
      this.formData = {};
      this.findLocation();
    },

    computed: {
      name () {
        return ValidateText({
          text: this.formData.name,
          length: 3,
        })
      },

      nameInvalidFeedback () {
        if (this.name === null || this.name) {
          return ''
        } else if (this.formData.name.length < 3) {
          return 'Digite um nome com mais de 3 caracteres'
        }
      },

      capacity () {
        const { quantityMax } = this.formData
        return ValidationNumber({
          number: quantityMax,
          test: {
            value: 0,
            validation: '>',
          },
        })
      },

      minimal () {
        const { quantityMin } = this.formData
        return ValidationNumber({
          number: quantityMin,
          test: {
            value: 0,
            validation: '>',
          },
        })
      },

      isValidForm () {
        return this.formData && this.formData.name && this.formData.name.length;
      },

      typeDescription() {
        if (this.formData && this.formData.type) {
          switch (this.formData.type) {
            case 'F': return 'O endereço possui um item definido e o mesmo não é removido automaticamente';
            case 'D': return 'O endereço possui um item definido e o mesmo é automaticamente removido quando a quantidade chegar a 0';
            case 'U': return 'O endereço não possui item definido podendo receber diversos e múltiplos itens';
            default: return '';
          }
        } else {
          return '';
        }
      },

      capacityDisabled() {
        if (
          (this.formData.brand && this.formData.brand.id)
          || (this.selectedBrand && this.selectedBrand.id)
        ) {
          return false;
        }

        return this.formData.type === 'U';
      }
    },

    methods: {
      addReserve () {
        const { reserves } = this.formData

        if (reserves === undefined && this.selectedReserve.uuid !== null) {
          this.formData.reserves = [this.selectedReserve]
        } else {
          const found = reserves.some(({ uuid }) => uuid === this.selectedReserve.uuid)

          if (!found && this.selectedReserve.uuid !== null) {
            this.formData.reserves.push(this.selectedReserve)
            this.selectedReserve = {}
          }
        }
      },

      addItem () {
        if(this.selectedItem.id !== null) {
          this.formData.item = this.selectedItem
        }
      },

      async searchAllocation (searchText) {
        if (!this.inProcess && searchText.length > 3) {
          this.searchAllocationReserveInProcess = true
          this.allocations = await allocation.getAllByName(searchText)
          this.searchAllocationReserveInProcess = false
        }
      },

      async searchItem(searchText) {
        if (!this.searchItemInProcess && (
          (searchText && searchText.length) || !this.selectedItem || Object.keys(this.selectedItem).length == 0
        )) {
          if (this.itemSearchTimeout) {
            clearTimeout(this.itemSearchTimeout);
          }

          this.itemSearchTimeout = setTimeout(() => {
            this.searchItemInProcess = true

            if (searchText && searchText.length) {
              httpClient
              .post(`${process.env.VUE_APP_API_URL}items/condition?page=${0}&size=${10}`, {
                conditions: [
                  {
                    logicalOperator: 'OR',
                    conditions: [
                      {
                        field: 'description',
                        conditionalOperator: 'LIKE_START',
                        value: searchText
                      },
                      {
                        field: 'ref',
                        conditionalOperator: 'LIKE_END',
                        value: shared.mountReduzidoFilter(searchText)
                      },
                      {
                        field: 'sku',
                        conditionalOperator: 'LIKE',
                        value: searchText
                      },
                      {
                        field: 'gtin',
                        conditionalOperator: 'LIKE',
                        value: searchText
                      }
                    ]
                  }
                ]
              })
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel(searchText);
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            } else {
              httpClient
              .get(`${process.env.VUE_APP_API_URL}items/?page=${0}&size=${10}`)
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel(searchText);
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            }
          }, 500);
        }
      },

      handleData(data) {
        this.searchItemInProcess = false
        return data.data.content;
      },

      save(event) {
        event.preventDefault();
        event.stopPropagation();

        this.formData.item = this.selectedItem;
        if (!this.formData.item || !this.formData.item.id) {
          this.formData.item = null;
        }

        this.formData.brand = this.selectedBrand;
        if (!this.formData.brand || !this.formData.brand.id) {
          this.formData.brand = null;
        }

        /*this.formData.address = this.inputs.reduce((acc, { name }) => {
          const value = this.formData[name]
          acc.push(`${name} ${value}`)
          return acc
        }, []).join(', ')*/

        if (this.isValidForm && this.validateBrand()) {
          let reserves;

          if (this.formData.reserves) {
            reserves = this.formData.reserves.flatMap(({ uuid }) => uuid)
          }

          let location = this.formData;

          if (this.currentType !== 'U' && location.type === 'U') {
            location.item = null;
            location.quantityMin = null;
            location.quantityMax = null;
          } else if (this.currentType === 'U' && location.type !== 'U') {
            location.item = location.balances[0];
          }

          this.saving = true;
          allocation.save(location).then(res => {
            this.saving = false;
            this.$notify.success(`Endereço ${this.formData.name} salvo com sucesso`)

            if (res && res.data) {
              this.items = [];

              if (res.data.item) {
                this.items.push(res.data.item);
                this.setItemsLabel();
              }

              this.formData.id = res.data.id;
              this.formData.barcode = res.data.barcode;
              this.formData.item = res.data.item;

              if (this.formData.type === 'U' && this.currentType !== 'U') {
                this.getLocationItems();
              }

              this.currentType = res.data.type;
              this.reloadFormData();
            }
          }).catch((error) => {
            this.saving = false;
            if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError('Houve um erro ao salvar')
            }
          });
        } else {
          this.isValid = true
        }
      },

      resetForm () {
        const objectDefault = {
          id: '',
          name: '',
          address: '',
          quantityMax: '',
          quantityMin: '',
          item: {}
        }
        this.formData = objectDefault;
      },

      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      setItemsLabel(searchText = null) {
        if (this.items && this.items.length) {
          this.items.forEach(item => {
            item.label = this.getItemReduzidoFromRef(item.ref) + " - " + item.description;

            if(searchText){
              if(item.sku && (item.sku.indexOf(searchText) >= 0)){
                item.label += ' - Ref: ' + item.sku;
              }
              if(item.gtin && (item.gtin.indexOf(searchText) >= 0)){
                item.label += ' - Cód barras: ' + item.gtin;
              }
            }
          });

          this.items.push({ id: null, label: 'Nenhum' });
        }
      },

      reloadFormData() {
        this.$nextTick(() => {
          let formData = this.formData;
          this.formData = {};
          this.formData = formData;
        });
      },

      getLocationItems() {
        this.loadingLocationBalances = true;
        httpClient.get(
          `${process.env.VUE_APP_API_URL}items/location/${this.formData.id}?page=${this.balancesCurrentPage - 1}&size=${this.balancesPerPage}`
        ).then(({ data }) => {
          this.loadingLocationBalances = false;
          this.formData.balances = data.data.content;
          this.balancesTotalRows = data.data.totalElements
        }).catch((error) => {
          this.loadingLocationBalances = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      getNumberOfLocationItemsThatHaveQuantity() {
        this.loadingNumberOfitems = true;

        httpClient.get(`${process.env.VUE_APP_API_URL}locations/number-of-items-that-have-quantity/${this.formData.id}`).then(({ data }) => {
          this.loadingNumberOfitems = false;
          this.numberOfItemsThatHaveQuantity = data.data;
        }).catch((error) => {
          this.loadingNumberOfitems = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      balancesPageChanged(page) {
        this.balancesCurrentPage = page;
        this.getLocationItems();
      },

      findLocation() {
        if (this.$route.params.id) {
          this.loadingLocation = true;
          httpClient.get(`${process.env.VUE_APP_API_URL}locations/${this.$route.params.id}`)
          .then(data => {
            this.loadingLocation = false;
            this.formData = data.data.data;
            this.setLocationValues();
          }).catch(error => {
            this.loadingLocation = false;
            if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError("Houve um erro ao buscar o endereço");
            }
          });
        } else {
          this.setLocationValues();
        }
      },

      setLocationValues() {
        this.currentType = this.formData.type;
        this.selectedItem = this.formData.item || {}
        this.selectedBrand = this.formData.brand || {}

        if (!this.formData.type) {
          this.formData.type = 'F';
        }

        if (this.formData.item && this.formData.item.id) {
          this.items.push(this.formData.item);
          this.setItemsLabel();
        } else {
          this.searchItem(null);
        }

        if (this.formData.brand && this.formData.brand.id) {
          this.brands.push(this.formData.brand);
          this.brands.push({ id: null, name: 'Nenhuma' });
        } else {
          this.searchBrand(null);
        }

        if (this.formData.id && this.formData.type === 'U') {
          this.getLocationItems();
          this.getNumberOfLocationItemsThatHaveQuantity();
        } else {
          this.numberOfItemsThatHaveQuantity = 1;
        }

        this.getLocationGroupers();
      },

      alwaysTrueFilterPredicate() {
        return true;
      },

      getLocationGroupers() {
        this.loadingLocationGroupers = true;
        httpClient.get(`${process.env.VUE_APP_API_URL}location-groupers?page=0&size=100`)
        .then(data => {
          this.loadingLocationGroupers = false;
          this.locationGroupers = data.data.data.content;
          this.addMissingLocationGroups();
        }).catch(error => {
          this.loadingLocationGroupers = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError("Houve um erro ao buscar os agrupadores");
          }
        });
      },

      addMissingLocationGroups() {
        this.locationGroupers = shared.sortLocationGroupers(this.locationGroupers);
        this.locationGroupsSuggestions = {};
        this.loadingLocationGroupSuggestions = {};

        if (!this.formData.locationGroups) {
          this.formData.locationGroups = [];
        }

        this.locationGroupers.forEach(grouper => {
          this.locationGroupsSuggestions[grouper.id] = [];
          this.loadingLocationGroupSuggestions[grouper.id] = false;

          if (!this.formData.locationGroups.find(g => g.locationGrouper && g.locationGrouper.id === grouper.id)) {
            this.formData.locationGroups.push({
              locationGrouper: grouper
            });
          }
        });

        this.formData.locationGroups = shared.sortLocationGroups(this.formData.locationGroups, this.locationGroupers);
      },

      locationGroupSearchChanged(group) {
        if (!this.loadingLocationGroupSuggestions[group.locationGrouper.id] && group.name && group.name.length) {
          if (this.locationGroupSuggestionSearchTimeout) {
            clearTimeout(this.locationGroupSuggestionSearchTimeout);
          }

          this.locationGroupSuggestionSearchTimeout = setTimeout(() => {
            this.loadingLocationGroupSuggestions[group.locationGrouper.id] = true;
            httpClient.post(
              `${process.env.VUE_APP_API_URL}location-groups/select-and-where?page=${this.currentPage - 1}&size=${this.perPage}`, {
              select: ['id', 'name'],
              where: {
                conditions: [
                  {
                    field: 'locationGrouper.id',
                    value: group.locationGrouper.id
                  },
                  {
                    field: 'name',
                    conditionalOperator: 'LIKE_START',
                    value: group.name
                  }
                ]
              }
            }).then(({ data }) => {
              this.loadingLocationGroupSuggestions[group.locationGrouper.id] = false;
              this.locationGroupsSuggestions[group.locationGrouper.id] = data.data.content;
              let groupsWithoutAccents = [];

              this.locationGroupsSuggestions[group.locationGrouper.id].forEach(g => {
                let nameWithoutAccents = g.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

                if (g.name !== nameWithoutAccents) {
                  let newGroup = JSON.parse(JSON.stringify(g));
                  newGroup.name = nameWithoutAccents;
                  groupsWithoutAccents.push(newGroup);
                }
              });

              this.locationGroupsSuggestions[group.locationGrouper.id].push(...groupsWithoutAccents);
              let locationGroupsSuggestions = this.locationGroupsSuggestions;
              this.locationGroupsSuggestions = {};
              this.locationGroupsSuggestions = locationGroupsSuggestions;
            })
            .catch((error) => {
              this.loadingLocationGroupSuggestions[group.locationGrouper.id] = false;
              if (error.message) {
                this.$notify.error(error)
              }
              this.locationGroupsSuggestions[group.locationGrouper.id] = [];
            });
          }, 200);
        }
      },

      async searchBrand(searchText) {
        if (!this.searchBrandInProcess && (
          (searchText && searchText.length) || !this.selectedBrand || Object.keys(this.selectedBrand).length == 0
        )) {
          if (this.brandSearchTimeout) {
            clearTimeout(this.brandSearchTimeout);
          }

          this.brandSearchTimeout = setTimeout(() => {
            this.searchBrandInProcess = true

            if (searchText && searchText.length) {
              httpClient
              .post(`${process.env.VUE_APP_API_URL}brands/condition?page=${0}&size=${10}`, {
                conditions: [
                  {
                    logicalOperator: 'OR',
                    conditions: [
                      {
                        field: 'name',
                        conditionalOperator: 'LIKE_START',
                        value: searchText
                      },
                      {
                        field: 'ref',
                        value: shared.mountReduzidoFilter(searchText, 5)
                      }
                    ]
                  }
                ]
              })
              .then(({ data }) => {
                this.brands = this.handleBrandsData(data);
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            } else {
              httpClient
              .get(`${process.env.VUE_APP_API_URL}brands/?page=${0}&size=${10}`)
              .then(({ data }) => {
                this.brands = this.handleBrandsData(data);
              })
              .catch((error) => {
                this.searchBrandInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            }
          }, 500);
        }
      },

      handleBrandsData(data) {
        this.searchBrandInProcess = false
        data.data.content.push({ id: null, name: 'Nenhuma' });
        return data.data.content;
      },

      validateBrand() {
        if (this.formData.brand && this.formData.brand.id && this.formData.item && this.formData.item.id && (
          !this.formData.item.brand || this.formData.item.brand.id !== this.formData.brand.id
        )) {
          this.$notify.textError('Item não é compatível com a marca');
          return false;
        }

        return true;
      }
    },
  }
</script>

<style scoped>
  .margin-bottom {
    margin-bottom: 0.2rem;
  }

  .margin-left {
    margin-left: 0.2rem;
  }
</style>
